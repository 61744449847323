import React from "react"
import tw, { styled } from "twin.macro"
import { P, H2, H3, HeaderDivider } from "./page-elements"
import Image from "./image"
import Section from "./shared/section"
import { VisibleElement } from "./shared/visible-element"
import { fadeIn } from "./shared/keyframes"

const Content = {
  src: "painting.jpg",
  alt: "Painting",
  since: "Since 1978",
  title: "Creating loyal customers throughout the Madison metropolitan area.",
  text:
    "Glacier Painting offers Residential Painting Services & Commercial Painting Services to Madison, Dane County, and all of Southern Wisconsin. We are fully licensed and insured & Encourage you to navigate through our website and take advantage of our Affordable Madison Interior Painting Services, Madison Deck & Wood Refinishing Services, & other residential & commercial painting contractor services.",
}

const AboutSection = () => {
  return (
    <Section classes="bg-base-secondary-color flex-row" size="xl">
      <VisibleElement>
        {({ visible }: { visible: boolean }) => {
          return (
            <AboutContent className={visible ? "visible" : ""}>
              <ImageColumn>
                <ImageContainer>
                  <AboutImage src={Content.src} alt={Content.alt} />
                </ImageContainer>
              </ImageColumn>
              <TextColumn>
                <H3Mono>{Content.since}</H3Mono>
                <StyledH2 color="darker">{Content.title}</StyledH2>
                <div className="flex">
                  <HeaderDivider />
                </div>
                <LightText color="dark">{Content.text}</LightText>
              </TextColumn>
            </AboutContent>
          )
        }}
      </VisibleElement>
    </Section>
  )
}

const AboutContent = styled.div`
  ${tw`
    flex
    flex-col
    md:justify-between
    md:flex-row
    w-full
    max-w-screen-lg
    m-auto
    px-8
  `};
  opacity: 0;
  &.visible {
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
  }
`

const ImageColumn = tw.div`
  px-4
  mx-auto
  w-full
  md:w-6/12
  md:mr-4
`

const TextColumn = tw.div`
  px-4
  md:px-0
  md:my-0
  w-full
  md:w-7/12
`

const ImageContainer = styled.div`
  ${tw`
    mx-auto
  `}
`

const AboutImage = styled(Image)`
  ${tw`w-full h-full`}
`

const LightText = styled(P)`
  ${tw`
    mb-4
  `}
`

const H3Mono = styled(H3)`
  ${tw`
    z-10
    font-normal
    uppercase
    text-sm
    text-header
  `}
  letter-spacing: .50em;
`

const StyledH2 = styled(H2)`
  ${tw`
    text-2xl
    lg:text-4xl
    lg:leading-normal
    text-header
  `}
`

export default AboutSection
